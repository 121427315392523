/** Middleware Actions **/
/* Socket Middleware Action */
export const INITIALIZE_SOCKET_CONNECTION = "INITIALIZE_SOCKET_CONNECTION";
export const SET_AUTH_CONSTRUCTOR = "SET_AUTH_CONSTRUCTOR";
export const SOCKET_CONNECTED = "SOCKET_CONNECTED";
export const SOCKET_DISCONNECTED = "SOCKET_DISCONNECTED";
export const SOCKET_REFRESH = "SOCKET_REFRESH";
export const SOCKET_SEND_MESSAGE = "SOCKET_SEND_MESSAGE";

/* Auth Actions */
export const RESET_AUTH_SUCCESS = "RESET_AUTH_SUCCESS";
export const UPDATE_AUTH_SUCCESS = "UPDATE_AUTH_SUCCESS";
export const UPDATE_USER_PROPERTIES_SUCCESS = "UPDATE_USER_PROPERTIES_SUCCESS";

/* Affiliate Details */
export const CREATE_AFFILIATE_SUCCESS = "CREATE_AFFILIATE_SUCCESS";
export const DELETE_AFFILIATE_SUCCESS = "DELETE_AFFILIATE_SUCCESS";
export const GET_AFFILIATE_SUCCESS = "GET_AFFILIATE_SUCCESS";
export const UPDATE_AFFILIATE_SUCCESS = "UPDATE_AFFILIATE_SUCCESS";
export const SEARCH_AFFILIATES_SUCCESS = "SEARCH_AFFILIATES_SUCCESS";
export const SET_ACTIVE_AFFILIATE_SUCCESS = "SET_ACTIVE_AFFILIATE_SUCCESS";

/* Assumption Actions */
export const CREATE_ASSUMPTION_SUCCESS = "CREATE_ASSUMPTION_SUCCESS";
export const DELETE_ASSUMPTION_SUCCESS = "DELETE_ASSUMPTION_SUCCESS";
export const GET_ASSUMPTION_SUCCESS = "GET_ASSUMPTION_SUCCESS";
export const SEARCH_ASSUMPTIONS_SUCCESS = "SEARCH_ASSUMPTIONS_SUCCESS";
export const UPDATE_ASSUMPTION_SUCCESS = "UPDATE_ASSUMPTION_SUCCESS";

/* Chat Channels */
export const CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS";
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS";
export const SEARCH_CHANNELS_SUCCESS = "SEARCH_CHANNELS_SUCCESS";
export const SET_ACTIVE_CHANNEL_SUCCESS = "SET_ACTIVE_CHANNEL_SUCCESS";

/* Device Details */
export const GET_DEVICE_DEFAULTS_SUCCESS = "GET_DEVICE_DEFAULTS_SUCCESS";
export const PAGE_REDIRECT_SUCCESS = "PAGE_REDIRECT_SUCCESS";
export const SITE_REDIRECT_SUCCESS = "SITE_REDIRECT_SUCCESS";
export const UPDATE_DEVICE_DETAILS = "UPDATE_DEVICE_DETAILS";
export const ADD_PUSH_SUBSCRIPTION_SUCCESS = "ADD_PUSH_SUBSCRIPTION_SUCCESS";
export const REMOVE_PUSH_SUBSCRIPTION_SUCCESS =
  "REMOVE_PUSH_SUBSCRIPTION_SUCCESS";

/** Flow Control */
export const TOGGLE_FLOW_SUCCESS = "TOGGLE_FLOW_SUCCESS";

/* Property Contact Details */
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const SEARCH_CONTACTS_SUCCESS = "SEARCH_CONTACTS_SUCCESS";
export const SHARE_CONTACT_SUCCESS = "SHARE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";

/* Members Actions */
export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const GET_MEMBER_SUCCESS = "GET_MEMBER_SUCCESS";
export const SEARCH_MEMBERS_SUCCESS = "SEARCH_MEMBERS_SUCCESS";
export const SET_ACTIVE_MEMBER_SUCCESS = "SET_ACTIVE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";

/** Member Groups */
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const SEARCH_GROUPS_SUCCESS = "SEARCH_GROUPS_SUCCESS";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";

/** Member Data Actions */
export const SEARCH_MEMBER_BUSINESS_NAMES_SUCCESS =
  "SEARCH_MEMBER_BUSINESS_NAMES_SUCCESS";
export const SEARCH_MEMBER_BUSINESS_TYPES_SUCCESS =
  "SEARCH_MEMBER_BUSINESS_TYPES_SUCCESS";
export const SEARCH_MEMBER_CITIES_SUCCESS = "SEARCH_MEMBER_CITIES_SUCCESS";
export const SEARCH_MEMBER_PROPERTIES_SUCCESS =
  "SEARCH_MEMBER_PROPERTIES_SUCCESS";
export const SEARCH_MEMBER_RENTAL_TYPES_SUCCESS =
  "SEARCH_MEMBER_RENTAL_TYPES_SUCCESS";
export const SEARCH_MEMBER_STATES_SUCCESS = "SEARCH_MEMBER_STATES_SUCCESS";

/** Notification Details */
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const RESET_NOTIFICATIONS_SUCCESS = "RESET_NOTIFICATIONS_SUCCESS";
export const SEARCH_NOTIFICATIONS_SUCCESS = "SEARCH_NOTIFICATIONS_SUCCESS";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";

/* All things toggle */
export const TOGGLE_AFFILIATE_MODAL = "TOGGLE_AFFILIATE_MODAL";
export const TOGGLE_ARTICLE_MODAL = "TOGGLE_ARTICLE_MODAL";
export const TOGGLE_ASSUMPTIONS_MODAL = "TOGGLE_ASSUMPTIONS_MODAL";
export const TOGGLE_CHAT_SIDEBAR = "TOGGLE_CHAT_SIDEBAR";
export const TOGGLE_CONTACT_MODAL = "TOGGLE_CONTACT_MODAL";
export const TOGGLE_CONTACTS_LIST = "TOGGLE_CONTACTS_LIST";
export const TOGGLE_DEFINITION_DRAWER = "TOGGLE_DEFINITION_DRAWER";
export const TOGGLE_LEFT_SIDEBAR = "TOGGLE_LEFT_SIDEBAR";
export const TOGGLE_FILE_MODAL = "TOGGLE_FILE_MODAL";
export const TOGGLE_NOTIFICATION_SUCCESS = "TOGGLE_NOTIFICATION_SUCCESS";
export const TOGGLE_NOTIFICATIONS_MODAL = "TOGGLE_NOTIFICATIONS_MODAL";
export const TOGGLE_PREFERENCE_MODAL = "TOGGLE_PREFERENCE_MODAL";
export const TOGGLE_PROPERTY_MODAL = "TOGGLE_PROPERTY_MODAL";
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const TOGGLE_SUBSCRIPTION_SIDEBAR = "TOGGLE_SUBSCRIPTION_SIDEBAR";
export const TOGGLE_SYSTEM_MODAL = "TOGGLE_SYSTEM_MODAL";
export const TOGGLE_TAX_DISCLAIMER_MODAL = "TOGGLE_TAX_DISCLAIMER_MODAL";
export const TOGGLE_TESTIMONIAL_MODAL = "TOGGLE_TESTIMONIAL_MODAL";
export const TOGGLE_TOUR_SUCCESS = "TOGGLE_TOUR_SUCCESS";
export const TOGGLE_TRANSACTION_MODAL = "TOGGLE_TRANSACTION_MODAL";
export const TOGGLE_TRANSACTION_COLUMNS_MODAL =
  "TOGGLE_TRANSACTION_COLUMNS_MODAL";
export const TOGGLE_TRANSACTIONS_EXPORT_MODAL =
  "TOGGLE_TRANSACTIONS_EXPORT_MODAL";
export const TOGGLE_TICKET_MODAL = "TOGGLE_TICKET_MODAL";
export const TOGGLE_TUTORIAL_MODAL = "TOGGLE_TUTORIAL_MODAL";

/** The App Actions **/
/* Article Actions */
export const CREATE_ARTICLE_SUCCESS = "CREATE_ARTICLE_SUCCESS";
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS";
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
export const GET_FEATURED_ARTICLE_SUCCESS = "GET_FEATURED_ARTICLE_SUCCESS";
export const SEARCH_ARTICLES_SUCCESS = "SEARCH_ARTICLES_SUCCESS";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";

/** Preference Actions */
export const GET_PREFERENCE_SUCCESS = "GET_PREFERENCE_SUCCESS";
export const SEARCH_PREFERENCES_SUCCESS = "SEARCH_PREFERENCES_SUCCESS";
export const UPDATE_PREFERENCE_SUCCESS = "UPDATE_PREFERENCE_SUCCESS";

/** Metric Actions */
export const CREATE_METRIC_TEMPLATE_SUCCESS = "CREATE_METRIC_TEMPLATE_SUCCESS";
export const DELETE_METRIC_TEMPLATE_SUCCESS = "DELETE_METRIC_TEMPLATE_SUCCESS";
export const GET_METRIC_TEMPLATE_SUCCESS = "GET_METRIC_TEMPLATE_SUCCESS";
export const SEARCH_METRIC_TEMPLATES_SUCCESS =
  "SEARCH_METRIC_TEMPLATES_SUCCESS";
export const UPDATE_METRIC_TEMPLATE_SUCCESS = "UPDATE_METRIC_TEMPLATE_SUCCESS";

/* Property Metric Actions */
export const GET_PLATFORM_METRICS_SUCCESS = "GET_PLATFORM_METRICS_SUCCESS";
export const GET_PORTFOLIO_METRICS_SUCCESS = "GET_PORTFOLIO_METRICS_SUCCESS";
export const GET_PROPERTIES_METRICS_SUCCESS = "GET_PROPERTIES_METRICS_SUCCESS";
export const GET_PROPERTY_METRICS_SUCCESS = "GET_PROPERTY_METRICS_SUCCESS";
export const GET_PROPERTY_UNITS_METRICS_SUCCESS =
  "GET_PROPERTY_UNITS_METRICS_SUCCESS";

/* Property Action */
export const ARCHIVE_PROPERTY_SUCCESS = "ARCHIVE_PROPERTY_SUCCESS";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const GET_PROPERTY_SUCCESS = "GET_PROPERTY_SUCCESS";
export const GET_PROPERTY_MORTGAGE_SUCCESS = "GET_PROPERTY_MORTGAGE_SUCCESS";
export const RESET_TRANSACTIONS_SUCCESS = "RESET_TRANSACTIONS_SUCCESS";
export const SEARCH_PROPERTIES_SUCCESS = "SEARCH_PROPERTIES_SUCCESS";
export const SEARCH_ASSOCIATED_TRANSACTIONS_SUCCESS =
  "SEARCH_ASSOCIATED_TRANSACTIONS_SUCCESS";
export const SET_ACTIVE_PROPERTY_SUCCESS = "SET_ACTIVE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";

/* Property Unit Actions */
export const CREATE_UNIT_SUCCESS = "CREATE_UNIT_SUCCESS";
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS";
export const GET_UNIT_SUCCESS = "GET_UNIT_SUCCESS";
export const SEARCH_UNITS_SUCCESS = "SEARCH_UNITS_SUCCESS";
export const SET_ACTIVE_UNIT_SUCCESS = "SET_ACTIVE_UNIT_SUCCESS";
export const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";

/* Propety Settings Actions */
export const CREATE_SETTING_SUCCESS = "CREATE_SETTING_SUCCESS";
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
export const SEARCH_SETTINGS_SUCCESS = "SEARCH_SETTINGS_SUCCESS";
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";

/* Property System Actions */
export const CREATE_SYSTEM_SUCCESS = "CREATE_SYSTEM_SUCCESS";
export const DELETE_SYSTEM_SUCCESS = "DELETE_SYSTEM_SUCCESS";
export const GET_SYSTEM_SUCCESS = "GET_SYSTEM_SUCCESS";
export const SEARCH_INDUSTRY_SYSTEM_DATA_SUCCESS =
  "SEARCH_INDUSTRY_SYSTEM_DATA_SUCCESS";
export const SEARCH_SYSTEMS_SUCCESS = "SEARCH_SYSTEMS_SUCCESS";
export const SET_ACTIVE_SYSTEM_SUCCESS = "SET_ACTIVE_SYSTEM_SUCCESS";
export const UPDATE_SYSTEM_SUCCESS = "UPDATE_SYSTEM_SUCCESS";

/** Forecasting Actions */
export const GET_PORTFOLIO_FORECAST_SUCCESS = "GET_PORTFOLIO_FORECAST_SUCCESS";
export const GET_PROPERTY_FORECAST_SUCCESS = "GET_PROPERTY_FORECAST_SUCCESS";
export const GET_UNIT_FORECAST_SUCCESS = "GET_UNIT_FORECAST_SUCCESS";

/** Report Actions */
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const DELETE_GENERATED_REPORT_SUCCESS =
  "DELETE_GENERATED_REPORT_SUCCESS";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const SEARCH_REPORTS_SUCCESS = "SEARCH_REPORTS_SUCCESS";
export const SEARCH_GENERATED_REPORTS_SUCCESS =
  "SEARCH_GENERATED_REPORTS_SUCCESS";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";

/** Trouble Ticket Actions */
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const SEARCH_TICKETS_SUCCESS = "SEARCH_TICKETS_SUCCESS";
export const SET_ACTIVE_TICKET_SUCCESS = "SET_ACTIVE_TICKET_SUCCESS";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";

/* TROUBLE TICKET NOTE ACTIONS */ // PIERRE ADDED THIS
export const CREATE_TICKET_NOTE_SUCCESS = "CREATE_TICKET_NOTE_SUCCESS";
export const DELETE_TICKET_NOTE_SUCCESS = "DELETE_TICKET_NOTE_SUCCESS";
export const GET_TICKET_NOTE_SUCCESS = "GET_TICKET_NOTE_SUCCESS";
export const SEARCH_TICKETS_NOTE_SUCCESS = "SEARCH_TICKETS_NOTE_SUCCESS";
export const SET_ACTIVE_TICKET_NOTE_SUCCESS = "SET_ACTIVE_TICKET_SUCCESS";
export const UPDATE_TICKET_NOTE_SUCCESS = "UPDATE_TICKET_NOTE_SUCCESS";
export const SEARCH_TICKET_NOTES_SUCCESS = "SEARCH_TICKET_NOTES_SUCCESS";

/** Chat Channel Actions */
export const ARCHIVE_CHAT_CHANNEL_SUCCESS = "ARCHIVE_CHAT_CHANNEL_SUCCESS";
export const CREATE_CHAT_CHANNEL_SUCCESS = "CREATE_CHAT_CHANNEL_SUCCESS";
export const DELETE_CHAT_CHANNEL_SUCCESS = "DELETE_CHAT_CHANNEL_SUCCESS";
export const GET_CHAT_CHANNEL_SUCCESS = "GET_CHAT_CHANNEL_SUCCESS";
export const SEARCH_CHAT_CHANNELS_SUCCESS = "SEARCH_CHAT_CHANNELS_SUCCESS";
export const SET_ACTIVE_CHAT_CHANNEL_SUCCESS =
  "SET_ACTIVE_CHAT_CHANNEL_SUCCESS";
export const UPDATE_CHAT_CHANNEL_SUCCESS = "UPDATE_CHAT_CHANNEL_SUCCESS";

/** Chat Channel Message(s) Actions */
export const CREATE_CHAT_CHANNEL_MESSAGE_SUCCESS =
  "CREATE_CHAT_CHANNEL_MESSAGE_SUCCESS";
export const DELETE_CHAT_CHANNEL_MESSAGE_SUCCESS =
  "DELETE_CHAT_CHANNEL_MESSAGE_SUCCESS";
export const GET_CHAT_CHANNEL_MESSAGE_SUCCESS =
  "GET_CHAT_CHANNEL_MESSAGE_SUCCESS";
export const SEARCH_CHAT_CHANNEL_MESSAGES_SUCCESS =
  "SEARCH_CHAT_CHANNEL_MESSAGES_SUCCESS";
export const SET_ACTIVE_CHAT_CHANNEL_MESSAGE_SUCCESS =
  "SET_ACTIVE_CHAT_CHANNEL_MESSAGE_SUCCESS";
export const UPDATE_CHAT_CHANNEL_MESSAGE_SUCCESS =
  "UPDATE_CHAT_CHANNEL_MESSAGE_SUCCESS";

/* Property, Transaction, Image, File Actions */
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const RESET_FILES_SUCCESS = "RESET_FILES_SUCCESS";
export const RESET_FILE_FILTER_SUCCESS = "RESET_FILE_FILTER_SUCCESS";
export const SEARCH_FILES_SUCCESS = "SEARCH_FILES_SUCCESS";
export const SHARE_FILE_SUCCESS = "SHARE_FILE_SUCCESS";
export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS";

/** Dropped Files Pending Customer Assignment */
export const RESET_DROPPED_FILES_SUCCESS = "RESET_DROPPED_FILES_SUCCESS";

/* Property Tag Actions */
export const SEARCH_TAGS_SUCCESS = "SEARCH_TAGS_SUCCESS";

/* Stripe Actions */
export const STRIPE_CARD_SEARCH = "STRIPE_CARD_SEARCH";
export const STRIPE_INVOICE_SEARCH = "STRIPE_INVOICE_SEARCH";
export const STRIPE_SUBSCRIPTION_CANCEL = "STRIPE_SUBSCRIPTION_CANCEL";
export const STRIPE_SUBSCRIPTION_CREATE = "STRIPE_SUBSCRIPTION_CREATE";
export const STRIPE_SUBSCRIPTION_DOWNGRADE = "STRIPE_SUBSCRIPTION_DOWNGRADE";
export const STRIPE_SUBSCRIPTION_ERROR = "STRIPE_SUBSCRIPTION_ERROR";
export const STRIPE_SUBSCRIPTION_REQUIRES_ACTION =
  "STRIPE_SUBSCRIPTION_REQUIRES_ACTION";
export const STRIPE_SUBSCRIPTION_RETRY = "STRIPE_SUBSCRIPTION_RETRY";
export const STRIPE_SUBSCRIPTION_SEARCH = "STRIPE_SUBSCRIPTION_SEARCH";
export const STRIPE_SUBSCRIPTION_UPGRADE = "STRIPE_SUBSCRIPTION_UPGRADE";

/** Template Actions */
export const CREATE_NOTIFICATION_TEMPLATE_SUCCESS =
  "CREATE_NOTIFICATION_TEMPLATE_SUCCESS";
export const DELETE_NOTIFICATION_TEMPLATE_SUCCESS =
  "DELETE_NOTIFICATION_TEMPLATE_SUCCESS";
export const GET_NOTIFICATION_TEMPLATE_SUCCESS =
  "GET_NOTIFICATION_TEMPLATE_SUCCESS";
export const SEARCH_NOTIFICATION_TEMPLATES_SUCCESS =
  "SEARCH_NOTIFICATION_TEMPLATES_SUCCESS";
export const SET_ACTIVE_NOTIFICATION_TEMPLATE_SUCCESS =
  "SET_ACTIVE_NOTIFICATION_TEMPLATE_SUCCESS";
export const UPDATE_NOTIFICATION_TEMPLATE_SUCCESS =
  "UPDATE_NOTIFICATION_TEMPLATE_SUCCESS";

/** Testimonial Actions */
export const CREATE_TESTIMONIAL_SUCCESS = "CREATE_TESTIMONIAL_SUCCESS";
export const DELETE_TESTIMONIAL_SUCCESS = "DELETE_TESTIMONIAL_SUCCESS";
export const GET_TESTIMONIAL_SUCCESS = "GET_TESTIMONIAL_SUCCESS";
export const SEARCH_TESTIMONIALS_SUCCESS = "SEARCH_TESTIMONIALS_SUCCESS";
export const SET_ACTIVE_TESTIMONIAL_SUCCESS = "SET_ACTIVE_TESTIMONIAL_SUCCESS";
export const UPDATE_TESTIMONIAL_SUCCESS = "UPDATE_TESTIMONIAL_SUCCESS";

/* Transaction Actions */
export const ARCHIVE_TRANSACTION_SUCCESS = "ARCHIVE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
export const EXPORT_TRANSACTIONS_SUCCESS = "EXPORT_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const SEARCH_TRANSACTION_RATES_SUCCESS =
  "SEARCH_TRANSACTION_RATES_SUCCESS";
export const SEARCH_TRANSACTIONS_SUCCESS = "SEARCH_TRANSACTIONS_SUCCESS";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";

/* App General Support Actions */
export const CREATE_DEFINITION_SUCCESS = "CREATE_DEFINITION_SUCCESS";
export const DELETE_DEFINITION_SUCCESS = "DELETE_DEFINITION_SUCCESS";
export const GET_DEFINITION_SUCCESS = "GET_DEFINITION_SUCCESS";
export const SEARCH_DEFINITIONS_SUCCESS = "SEARCH_DEFINITIONS_SUCCESS";
export const UPDATE_DEFINITION_SUCCESS = "UPDATE_DEFINITION_SUCCESS";

/** Video Actions */
export const CREATE_VIDEO_SUCCESS = "CREATE_VIDEO_SUCCESS";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS";
export const SET_ACTIVE_VIDEO_SUCCESS = "SET_ACTIVE_VIDEO_SUCCESS";
export const SEARCH_VIDEOS_SUCCESS = "SEARCH_VIDEOS_SUCCESS";
export const SEARCH_VIDEO_CATEGORIES_SUCCESS =
  "SEARCH_VIDEO_CATEGORIES_SUCCESS";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";

import { Suspense, lazy } from "react";

/** Vendors */
import { Navigate, createBrowserRouter } from "react-router-dom";

/** Custom Layouts */
import AppLayout from "@components/shared/layout/App";
import ContentLoading from "@components/shared/loading/Content";
import Dashboard from "@components/dashboard/views/Dashboard";
import ProfileLayout from "@components/shared/layout/Profile";

const Article = lazy(() => import("../components/articles/views/Details"));
const CapEx = lazy(() => import("../components/capex/views/CapEx"));
const Contacts = lazy(() => import("../components/contact/views/Contacts"));
const Documents = lazy(() => import("../components/files/views/Files"));
const Education = lazy(() => import("../components/articles/views/List"));
const Platform = lazy(() => import("../components/platform/views/Overview"));
const Profile = lazy(() => import("../components/profile/views/Profile"));
const Property = lazy(() => import("../components/property/views/Property"));
const Reports = lazy(() => import("../components/report/views/Reports"));
const Revoked = lazy(() => import("../components/auth/views/Revoked"));

const router = (is_admin: boolean) =>
  createBrowserRouter([
    {
      element: <AppLayout />,
      children: [
        {
          element: <Dashboard />,
          path: "dashboard",
        },
        {
          element: (
            <Suspense fallback={<ContentLoading />}>
              <Property />
            </Suspense>
          ),
          path: "dashboard/:handle",
        },
        {
          element: (
            <Suspense fallback={<ContentLoading />}>
              <Education />
            </Suspense>
          ),
          path: "education",
        },
        {
          element: (
            <Suspense fallback={<ContentLoading />}>
              <Article />
            </Suspense>
          ),
          path: "education/:handle",
        },
        {
          element: (
            <Suspense fallback={<ContentLoading />}>
              <CapEx />
            </Suspense>
          ),
          path: "capex",
        },
        {
          element: (
            <Suspense fallback={<ContentLoading />}>
              <Contacts />
            </Suspense>
          ),
          path: "contacts",
        },
        {
          element: (
            <Suspense fallback={<ContentLoading />}>
              <Documents />
            </Suspense>
          ),
          path: "documents",
        },
        {
          element: (
            <Suspense fallback={<ContentLoading />}>
              <Reports />
            </Suspense>
          ),
          path: "reports",
        },
        {
          element: is_admin ? (
            <Suspense fallback={<ContentLoading />}>
              <Platform />
            </Suspense>
          ) : (
            <Navigate to="/dashboard" />
          ),
          path: "platform",
        },
      ],
    },
    {
      element: <ProfileLayout />,
      children: [
        {
          element: (
            <Suspense fallback={<ContentLoading />}>
              <Profile />
            </Suspense>
          ),
          path: "profile/:page",
        },
        {
          path: "profile/*",
          element: <Navigate to="/profile/details" replace />,
        },
      ],
    },
    {
      element: (
        <Suspense fallback={<ContentLoading />}>
          <Revoked />
        </Suspense>
      ),
      path: "access/revoked",
    },
    {
      element: <Navigate to="/dashboard" replace />,
      index: true,
      path: "/",
    },
  ]);

export default router;

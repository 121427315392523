/** Redux */
import * as types from "@redux/actions/types";

/** Types */
import type { IBasicRecord, IToggleAction } from "types";

function removeResources(
  state: any,
  action: IBasicRecord | { results: IBasicRecord[] },
  key: string
) {
  //Potentially many item removal.
  return Object.prototype.hasOwnProperty.call(action, "results")
    ? Array.isArray(state[0])
      ? state
          .map((a: IBasicRecord) =>
            a.filter(
              (b: IBasicRecord) =>
                !action.results
                  .map((c: IBasicRecord) => c.resource_name)
                  .includes(b.resource_name)
            )
          )
          .filter((d: IBasicRecord[]) => d.length > 0)
      : state.map(
          (a: IBasicRecord) =>
            !action.results
              .map((c: IBasicRecord) => c.resource_name)
              .includes(a.resource_name)
        )
    : //Single Item Removal.
    Array.isArray(state)
    ? state.filter(
        (a) =>
          a.resource_name !== action[key as keyof typeof action].resource_name
      )
    : //Single Item Delete or Archive.
    action[key as keyof typeof action].resource_name === state.resource_name
    ? {}
    : state;
}

function updateResources(
  state: any,
  action: IBasicRecord | { results: IBasicRecord[] },
  key: string
) {
  //Potentially many item update.
  return Object.prototype.hasOwnProperty.call(action, "results")
    ? state.map((block: IBasicRecord | IBasicRecord[]) => {
        /** Multiple Match */
        if (Array.isArray(block)) {
          return block.map((record: IBasicRecord) => {
            const match1 = action.results.find(
              (r: IBasicRecord) => record.resource_name === r.resource_name
            );
            return match1 ? { ...record, ...match1 } : record;
          });
          /** Single Match */
        } else {
          const match2 = action.results.find((record: IBasicRecord) => {
            return block.resource_name === record.resource_name;
          });
          return match2 ? { ...block, ...match2 } : block;
        }
      })
    : //Single Item Update.
    Array.isArray(state)
    ? state.map((a) =>
        a.resource_name === action[key as keyof typeof action].resource_name
          ? { ...a, ...action[key as keyof typeof action] }
          : a
      )
    : //Single Item Create or Update
    action[key as keyof typeof action].resource_name === state.resource_name
    ? { ...state, ...action[key as keyof typeof action] }
    : state;
}

const reduxMethods = {
  addResources: (state: any, action: any, key: string) => {
    return {
      ...state,
      all: [
        ...state.all,
        ...(Object.prototype.hasOwnProperty.call(action, "results")
          ? action.results
          : [action[key]]),
      ],
      filtered: [
        ...state.filtered,
        ...(Object.prototype.hasOwnProperty.call(action, "results")
          ? action.results
          : [action[key]]),
      ],
    };
  },
  applyFilteredResults: (
    state: any,
    action: { dataSetKey: string; results: IBasicRecord[] }
  ) => {
    const join = action.dataSetKey === state.dataSetKey;
    return {
      ...state,
      dataSetKey: action.dataSetKey,
      filtered: join ? [...state.filtered, ...action.results] : action.results,
    };
  },
  joinOrOverwrite: (
    state: any,
    action: { dataSetKey: string; results: IBasicRecord[] }
  ) => {
    let join = action.dataSetKey === state.dataSetKey;
    let all = join ? [...state.all, ...action.results] : action.results;
    let filtered = join
      ? [...state.filtered, ...action.results]
      : action.results;
    return {
      ...state,
      dataSetKey: action.dataSetKey,
      all: reduxMethods.orderResources(all),
      filtered: reduxMethods.orderResources(filtered),
    };
  },
  joinParticipants: (state: any, action: { results: IBasicRecord[] }) => {
    let rIds = action.results.map((a) => a.resource_name);
    return {
      ...state,
      all: state.all.map((record: IBasicRecord) => {
        let pos1 = rIds.indexOf(record.resource_name);
        return pos1 > -1
          ? { ...record, participants: action.results[pos1].participants }
          : record;
      }),
      filtered: state.filtered.map((record: IBasicRecord) => {
        let pos2 = rIds.indexOf(record.resource_name);
        return pos2 > -1
          ? { ...record, participants: action.results[pos2].participants }
          : record;
      }),
    };
  },
  orderResources: (resources = []) => {
    if (resources?.length === 0) return resources;
    return [...resources].sort((a, b) =>
      Array.isArray(a) ? a[0].created - b[0].created : a.created - b.created
    );
  },
  removeDuplicates: (state: any, action: { results: IBasicRecord[] }) => {
    let all = [...state.all, ...(action.results || [])].reduce((r, props) => {
      r[props.resource_name] = props;
      return r;
    }, {});

    let filtered = [...state.filtered, ...(action.results || [])].reduce(
      (r, props) => {
        r[props.resource_name] = props;
        return r;
      },
      {}
    );
    return {
      ...state,
      all: Object.keys(all).map((key: string) => all[key]),
      filtered: Object.keys(filtered).map((key: string) => filtered[key]),
    };
  },
  removeMatchCase: (state: any, action: any, key: string) => {
    return {
      ...state,
      all: removeResources(state.all || state, action, key),
      filtered: removeResources(state.filtered || state, action, key),
    };
  },
  resetResources: (state: any) => {
    return {
      ...state,
      all: state.all,
      filtered: [],
    };
  },
  updateMatchCase: (state: any, action: any, key: string) => {
    return {
      ...state,
      all: updateResources(state.all || state, action, key),
      filtered: updateResources(state.filtered || state, action, key),
    };
  },
  single: {
    //Assume state is default { all: [], filtered: [] } structure. action is { [key]: props };
    addResource: (state: any, action: any, key: string) => {
      return {
        ...state,
        all: [...state.all, action[key]],
        filtered: [...state.filtered, action[key]],
      };
    },
    applyFlowControl: (state: any, action: any, key: string) => {
      if (action.details?.resource_id === key) {
        return action.details;
      }
      return state;
    },
    //Toggle modals, sidebars, etc.
    toggleState: (
      state: any,
      action: IToggleAction & { type: typeof types },
      key: string
    ) => {
      return { ...state, [key]: action.show === false ? false : !state[key] };
    },
    //Assume state is default { props } structure. action is { [key]: props };
    updateMatchCase: (state: any, action: any, key: string) => {
      let match = action[key].resource_name === state.resource_name;
      return match ? { ...state, ...action[key] } : state;
    },
    removeMatchCase: (state: any, action: any, key: string) => {
      let match = action[key].resource_name === state.resource_name;
      return match ? {} : state;
    },
  },
  multi: {
    //Assume state is default { all: [], filtered: [] } structure. action is { results };
    addResource: (state: any, action: { results: IBasicRecord[] }) => {
      return {
        ...state,
        all: [...state.all, ...action.results],
        filtered: [...state.filtered, ...action.results],
      };
    },
    //Assume state is default { all: [], filtered: [] } structure. action is { results: [] };
    removeDuplicates: (state: any, action: { results: IBasicRecord[] }) => {
      let all = [...state.all, ...(action.results || [])];
      let filtered = [...state.filtered, ...(action.results || [])];

      function removeDups(
        r: { [key: string]: IBasicRecord },
        record: IBasicRecord
      ) {
        r[record.resource_name] = record;
        return r;
      }
      return {
        ...state,
        all: Object.values(all.reduce(removeDups, {})),
        filtered: Object.values(filtered.reduce(removeDups, {})),
      };
    },
    //Assume state is default { all: [], filtered: [] } structure. action is { [key]: props };
    removeMatchCase: (
      state: any,
      action: { [key: string]: IBasicRecord },
      key: string,
      override = "resource_name"
    ) => {
      const removeMatch = (record: IBasicRecord) => {
        return record[override] !== action[key][override];
      };

      return {
        ...state,
        all: [...state.all].filter(removeMatch),
        filtered: [...state.filtered].filter(removeMatch),
      };
    },
    //Assume state is default { all: [], filtered: [] } structure. action is { [key]: props };
    updateMatchCase: (
      state: any,
      action: { [key: string]: IBasicRecord },
      key: string,
      override = "resource_name"
    ) => {
      const replaceMatch = (record: IBasicRecord) =>
        record[override] === action[key][override]
          ? { ...record, ...action[key] }
          : record;

      return {
        ...state,
        all: state.all.map(replaceMatch),
        filtered: state.filtered.map(replaceMatch),
      };
    },
  },
};
export default reduxMethods;

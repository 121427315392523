/** Vendor */
import { combineReducers } from "redux";
import { createAction, createReducer } from "@reduxjs/toolkit";

/** Custom Actions */
import * as types from "../actions/types";
import initialState from "./initialState";
import redux from "../../dist/js/redux";

/** Enums */
import { ResourceId } from "../../types";

const details = createReducer(initialState.report.details, (builder) => {
  builder
    .addCase(createAction(types.CREATE_REPORT_SUCCESS), (state, action) => {
      return action.report;
    })
    .addCase(createAction(types.DELETE_REPORT_SUCCESS), (state, action) => {
      return redux.single.removeMatchCase(state, action, ResourceId.Report);
    })
    .addCase(createAction(types.GET_REPORT_SUCCESS), (state, action) => {
      return action.report;
    })
    .addCase(createAction(types.TOGGLE_FLOW_SUCCESS), (state, action) => {
      return redux.single.applyFlowControl(state, action, ResourceId.Report);
    })
    .addCase(createAction(types.UPDATE_REPORT_SUCCESS), (state, action) => {
      return redux.single.updateMatchCase(state, action, ResourceId.Report);
    })
    .addDefaultCase((state) => state);
});

const list = createReducer(initialState.report.list, (builder) => {
  builder
    .addCase(createAction(types.CREATE_REPORT_SUCCESS), (state, action) => {
      return redux.single.addResource(state, action, ResourceId.Report);
    })
    .addCase(createAction(types.DELETE_REPORT_SUCCESS), (state, action) => {
      return redux.multi.removeMatchCase(state, action, ResourceId.Report);
    })
    .addCase(createAction(types.SEARCH_REPORTS_SUCCESS), (state, action) => {
      return redux.joinOrOverwrite(state, action);
    })
    .addCase(createAction(types.UPDATE_REPORT_SUCCESS), (state, action) => {
      return redux.multi.updateMatchCase(state, action, ResourceId.Report);
    })
    .addDefaultCase((state) => state);
});

const reports = combineReducers({ details, list });

export default reports;
